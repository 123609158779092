

*{
margin:0;
padding: 0;
scroll-behavior:smooth;
/* To apply the font to the entire body */

  font-family: 'Roboto Slab', sans-serif;
 


}
/*Form Validation */
.form-group .error {
  color: red;
  margin: 10px;
}

.form-group.success input {
  border-color: green;
}

.form-group.error input{
  border-color: red;
}

.gradientForBanner{
  background: #A770EF;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FDB99B, #CF8BF3, #A770EF);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FDB99B, #CF8BF3, #A770EF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}
.gradientForBanner p{
  
  font-family: 'Bebas Neue', sans-serif;
  text-shadow: 2px 2px 4px #000000, -2px -2px 4px #ffffff;
}
.text-with-shadow {
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
/* horizontal offset, vertical offset, blur radius, color */
}

.hoverEffect{
cursor: pointer;
transition: image 1s ease-in-out;
}
.hoverEffect:hover{
  color:#234986;
}
.imageFormard:hover {
    transform: scaleX(-1); /* Adjust the scaling factor as needed */
  }
.titleWithFont{
font-weight: 500;
letter-spacing: 1px;
}  
.smallNav{
  z-index: -1000px;
}



@media screen and (max-width:786px){
    
.hiddenComponents{
  display:none;
}
.insideWraper{
  margin-top:10% !important;
}
.titleForOr{
  font-size:20px !important;
}
.gradientForBanner p{
  font-size: 16px;
  letter-spacing: 2px;
}
.textContainerLayout{
  min-width:98vw;
}
.textContainerLayout p{
  text-align: center;
}
.bannerStyle{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content:center;
}
.bannerStyle:nth-child(2n){
  margin-left:-2rem;
}

  
}
@media screen and (min-width:786px){
    
  .smallNav{
    display: none;
  }
  .largeHidden{
    display: none;
  }
 
}
@media screen and (min-width: 1200px) {
  .smallNav{
    display: none;
  }
  .largeHidden{
    display: none;
  }
  
}


/* Styles for devices with a screen width between 768px and 1024px (typical tablet size) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .hiddenComponents{
    display:none;
  }
}
